import check from 'check-types';
import { DateTime } from 'luxon';
import BaseModel from '../base';
import DraftTenantModel from './tenant';
import DraftGuarantorModel from './guarantor';
import DraftFlatbondLandlordModel, { LANDLORD_UPSELL_STATUSES } from './landlord';
import FlatbondDocument from '../document';
import DraftLandlord from '@/models/flatbond_draft/landlord';
import { TraditionalDepositModel } from '@/models/flatbond/traditional_deposit';
import config from '@/libs/utils/config';
import { calculateRentByWeek } from '@/libs/utils/helpers';
import { RENT_PERIODS } from '@/libs/utils/constants';
import { TENANCY_TYPES } from '@/libs/constants/generatedConstants';
import { FLATBOND_COUNTRIES } from '@/libs/constants/generatedConstants';

export const RENT_DURATION_MONTHS_MIN = 6;
export const RENT_DURATION_MONTHS_MAX = 24;
export const COUNTRY_OPTIONS = {
  SCOTLAND: 'gb-sct',
  ENGLAND: 'gb-eng',
  WALES: 'gb-wls',
  UNITED_KINGDOM: 'gb'
};
export const FLATBOND_TYPE_OPTIONS = {
  ALL: 'all',
  NEW_TENANCY: 'new_tenancy',
  CONVERSION: 'conversion'
};
export const MANAGED_BY_OPTIONS = {
  AGENT: 'agent',
  LANDLORD: 'landlord'
};

export const PRODUCT_TYPE_OPTIONS = {
  FLATBOND: 'flatbond',
  TRADITIONAL_DEPOSIT: 'traditional_deposit',
  TRADITIONAL_DEPOSIT_LEGACY: 'traditional_deposit_legacy',
  UNSELECTED: 'unselected'
};

export const RENT_WEEKLY_MIN = 2500;
export const RENT_WEEKLY_MAX = 200000;

export const RENT_MONTHLY_MIN = 10900;
export const RENT_MONTHLY_MAX = 866600;

export const MIN_MONTH_CHECK = 0;
export const MIN_TENANCY_DURATION_IN_DAYS = 2;
export const MIN_UNLOCK_DEPOSIT_TENANCY_TERM_IN_MONTHS = 0;

export default class DraftModel extends BaseModel {
  constructor(data) {
    super(data);
    this.isRentAboveMin = this.isRentAboveMin.bind(this);
    this.isStartDateValid = this.isStartDateValid.bind(this);
    this.isCloseDateValid = this.isCloseDateValid.bind(this);
    this.isStartDateInFuture = this.isStartDateInFuture.bind(this);
    this.isRentDurationAboveMin = this.isRentDurationAboveMin.bind(this);
    this.isRemainingDurationAboveMin = this.isRemainingDurationAboveMin.bind(this);
    this.isRentDurationAboveMinDays = this.isRentDurationAboveMinDays.bind(this);
    this.isRentDurationBelowMax = this.isRentDurationBelowMax.bind(this);

    this.tenants = this.tenants.map(t => new DraftTenantModel(t));
    this.guarantors = this.guarantors.map(g => new DraftGuarantorModel(g));
    this.landlord = this.landlord ? new DraftFlatbondLandlordModel(this.landlord) : null;
    this.newFlatbondDocument = this.newFlatbondDocument
      ? new FlatbondDocument(this.newFlatbondDocument)
      : null;
    this.newFlatbondDocumentRentSchedule = this.newFlatbondDocumentRentSchedule
      ? new FlatbondDocument(this.newFlatbondDocumentRentSchedule)
      : null;
    this.traditionalDeposit = this.traditionalDeposit
      ? new TraditionalDepositModel(this.traditionalDeposit)
      : null;
  }

  get rentDuration() {
    if (this.isScotland) {
      return null;
    }
    if (this.closeDate === null || this.startDate === null) {
      return null;
    }
    const { months, days } = this._parsedCloseDateExclusive.diff(this._parsedStartDate, [
      'months',
      'days'
    ]);
    return { months, days };
  }

  set rentDuration({ months, days }) {
    if (this.isScotland) {
      this._parsedCloseDateExclusive = null;
      return;
    }
    if (this.startDate === null) return;
    this._parsedCloseDateExclusive = this._parsedStartDate.plus({ months, days });
  }

  /**
   * Parse the start date.
   * @returns {DateTime}
   * @private
   */
  get _parsedStartDate() {
    return DateTime.fromISO(this.startDate);
  }

  /**
   * Retrieve the close date, parsed to a DateTime.
   * @returns {DateTime}
   * @private
   */
  get _parsedCloseDate() {
    return DateTime.fromISO(this.closeDate);
  }

  /**
   * Update the parsed close-date.
   * @param value
   * @private
   */
  set _parsedCloseDate(value) {
    this.closeDate = value.toISODate();
  }

  /**
   * The getter returns the close date in the interval [startDate;closeDate[. This is useful
   * when calculating the tenancy duration.
   * @returns {DateTime}
   * @private
   */
  get _parsedCloseDateExclusive() {
    return this._parsedCloseDate.plus({ days: 1 });
  }

  get isScotland() {
    if (!this.country || !this.tenancyType) {
      return false;
    }

    return (
      this.country === FLATBOND_COUNTRIES.SCOTLAND &&
      this.tenancyType === TENANCY_TYPES.PRIVATE_RESIDENTIAL_TENANCY
    );
  }

  /**
   * Updates the close-date in the interval [startDate;closeDate[
   * @param dt
   * @private
   */
  set _parsedCloseDateExclusive(dt) {
    this._parsedCloseDate = dt.minus({ days: 1 });
  }

  defaults() {
    return {
      branch: null,
      branchHasTenancyProtectionGatewayEnabled: false,
      branchHasTraditionalDepositsHandledOffPlatform: false,
      managedBy: config.isOrganizationLandlord ? MANAGED_BY_OPTIONS.AGENT : '',
      tenantType: '',
      tenancyType: null,
      productType: PRODUCT_TYPE_OPTIONS.FLATBOND,
      rent: 0,
      rentPerMonth: 0,
      depositAmount: 0,
      rentPeriod: null,
      rentInAdvance: false,
      type: FLATBOND_TYPE_OPTIONS.NEW_TENANCY,
      startDate: null,
      closeDate: null,
      address: '',
      city: '',
      postcode: '',
      country: '',
      localAuthority: '',
      hasGuarantor: null,
      landlordEmail: '',
      newFlatbondDocument: null,
      newFlatbondDocumentRentSchedule: null,
      newConversionDocument: null,
      organizationSeller: null,
      newReferencingDocuments: [],
      tenants: [],
      landlord: null,
      landlordUpsellStatus: LANDLORD_UPSELL_STATUSES.NO_DEPOSIT_APPROVED,
      guarantors: [],
      application: null,
      isAstSigned: null,
      landlordInterestedInBoost: false,
      groupBoostCommission: 0,
      traditionalDeposit: null,
      propertyApplication: null,
      externalTenancyId: null,
      hasSelectedOrganizationSeller: false,
      flatbondCreatedId: null
    };
  }

  toFlatpieModel() {
    const data = super.toFlatpieModel();
    if (this._parsedStartDate) data.start_date = this._parsedStartDate.toISODate();
    if (this._parsedCloseDate) data.close_date = this._parsedCloseDate.toISODate();
    data.rent = calculateRentByWeek(this.rentPeriod, this.rent);

    if (check.object(this.newFlatbondDocument)) {
      data['new_flatbond_document_id'] = this.newFlatbondDocument.id;
    }
    if (check.object(this.newFlatbondDocumentRentSchedule)) {
      data['new_flatbond_document_rent_schedule_id'] = this.newFlatbondDocumentRentSchedule.id;
    }

    return data;
  }

  isRentAboveMin() {
    const aboveWeekMin = this.rentPeriod === RENT_PERIODS.WEEK && this.rent >= 2500;
    const aboveMonthMin = this.rentPeriod === RENT_PERIODS.MONTH && this.rent >= 11000;
    return aboveWeekMin || aboveMonthMin;
  }

  isConversion() {
    return this.type === FLATBOND_TYPE_OPTIONS.CONVERSION;
  }

  isStartDateValid() {
    return !this._parsedStartDate.invalid;
  }

  isCloseDateValid() {
    if (this.isScotland) {
      return true;
    }
    return !this._parsedCloseDate.invalid;
  }

  isStartDateInFuture() {
    if (this.isConversion()) {
      return true;
    }
    return (
      DateTime.local()
        .diff(this._parsedStartDate, 'days')
        .toObject().days < 1
    );
  }

  isRentDurationAboveMinDays() {
    if (this.isScotland) {
      return true;
    }
    if (
      config.organizationMinTenancyDurationInMonths === MIN_MONTH_CHECK &&
      this.rentDuration &&
      this.rentDuration.months === MIN_MONTH_CHECK
    ) {
      return this.rentDuration.days >= MIN_TENANCY_DURATION_IN_DAYS;
    }

    return true;
  }

  isRentDurationAboveMin() {
    if (this.isScotland) {
      return true;
    }
    return (
      !this.rentDuration ||
      this.rentDuration.months >= config.organizationMinTenancyDurationInMonths
    );
  }

  isRemainingDurationAboveMin() {
    if (this.isScotland) {
      return true;
    }
    if (!this.isConversion()) {
      return true;
    }
    return (
      this._parsedCloseDate.diff(DateTime.local(), 'months').toObject().months >=
      MIN_UNLOCK_DEPOSIT_TENANCY_TERM_IN_MONTHS
    );
  }

  isRentDurationBelowMax() {
    if (this.isScotland) {
      return true;
    }
    return (
      !this.rentDuration ||
      this.rentDuration.months < config.organizationMaxTenancyDurationInMonths ||
      (this.rentDuration.months === config.organizationMaxTenancyDurationInMonths &&
        this.rentDuration.days === 0)
    );
  }

  merge(other) {
    if (!this.landlord && other.landlord) {
      this.landlord = new DraftLandlord();
    }
    if (!this.traditionalDeposit && other.traditionalDeposit) {
      this.traditionalDeposit = new TraditionalDepositModel();
    }
    return super.merge(other);
  }

  fixupGuarantors() {
    if (!this.guarantors) return this;
    const guarantors = this.guarantors.map(guarantor => guarantor.fixupGuarantor(this.tenants));
    return new DraftModel({ ...this, guarantors });
  }
}
